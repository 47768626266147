import React from 'react'

const Candidates = [
  {
    position: "CLP SECTION",
    slug: "gemma-bolton",
    handle: "gembolton",
    name: "Gemma Bolton",
    imgUrl: "/img/gemma-bolton.png",
    labourMembershipNumber: "L1405940",
    blurb: "I’m standing for a third term on Labour’s NEC because I believe that we need a transformational Labour government and democratic party where members are respected and valued at every level. At the general election, Labour must propose transformative, bold and popular policies that stand in opposition to the Tories. We must oppose austerity during an unprecedented cost of living crisis; repeal...",
    body: <div>
      <p>I’m standing for a third term on Labour’s NEC because I believe that we need a transformational Labour government and democratic party where members are respected and valued at every level.</p>

      <p>At the general election, Labour must propose transformative, bold and popular policies that stand in opposition to the Tories. We must oppose austerity during an unprecedented cost of living crisis; repeal Tory anti-trade union legislation; support public ownership; campaign for a radical Green Prosperity Plan; fight rising poverty and racism and adopt a peace-oriented, independent foreign policy – which includes calling for an immediate, permanent ceasefire in Gaza.</p>

      <p>During over five years on the NEC, I’ve seen that we’re strongest as a party when members feel empowered and engaged. That’s why local parties should always select their parliamentary candidate.</p>

      <p>In addition to serving two terms on Labour’s NEC, I’ve also been a parliamentary candidate; served on the Women’s Conference Arrangements Committee; as CLP Equalities and Youth Officer; as Co-Chair of the Campaign for Labour Party Democracy (CLPD) and on the South East Regional Executive Committee since aged 18.</p>

      <p>I’m proudly endorsed by the Centre-Left Grassroots Alliance, including Momentum and CLPD. Please also vote: Jess Barnard, Yasmine Dar and Mish Rahman.</p>
    </div>,
  },
  {
    position: "CLP SECTION",
    slug: "jess-barnard",
    handle: "JessicaLBarnard",
    name: "Jess Barnard",
    imgUrl: "/img/jess-barnard.png",
    labourMembershipNumber: "L1204334",
    blurb: "I joined Labour at age 17 because I saw the damage a Tory government was inflicting on working class communities like mine and the desperate need for a socialist alternative. That’s why I’ve always worked tirelessly to tackle injustice, poverty and discrimination, whether as a Workplace Rep, Councillor, Parliamentary Candidate or Youth Worker. We need a Labour government which tackles inequality...",
    body: <div>
      <p>I joined Labour at age 17 because I saw the damage a Tory government was inflicting on working class communities like mine and the desperate need for a socialist alternative. That’s why I’ve always worked tirelessly to tackle injustice, poverty and discrimination, whether as a Workplace Rep, Councillor, Parliamentary Candidate or Youth Worker.</p>

      <p>We need a Labour government which tackles inequality head on, with a bold manifesto committed to funding and renationalising public services, addressing the housing emergency, delivering climate justice and putting human rights at the heart of international policy.</p>

      <p>My record:</p>

      <ul>
        <li>Worked to create new democratic Student and BAME structures</li>
        <li>A trade unionist and striking worker, supporting a new deal for working people and reversing Tory anti-union legislation.</li>
        <li>Supporting member democracy and transparency, opposed selection stitch-ups and equalities conference shutdowns on the NEC and NPF.</li>
        <li>Providing regular in person and digital report backs, always accountable to our membership.An outspoken voice for justice, transformative policies and calling for a ceasefire in Gaza.</li>
        <li>Standing for LGBTQ+ rights, disability justice and challenging the party to implement the Forde report, to tackle all forms of racism without delay.</li>
      </ul>

      <p>I’m proud to be supported by the Centre-Left Grassroots Alliance and Momentum. Please Also support Gemma Bolton, Yasmine Dar and Mish Rahman.</p>
    </div>
  },
  {
    position: "CLP SECTION",
    slug: "mish-rahman",
    handle: "mish_rahman",
    name: "Mish Rahman",
    imgUrl: "/img/mish-rahman.png",
    labourMembershipNumber: "L0137964",
    blurb: "As a working-class man of Bangladeshi heritage, whose father campaigned against the National Front, I am a committed anti-racist bringing my commitments to equality and justice onto the NEC since I was first elected in 2020. I have: Opposed the selection stitch-ups and crackdowns on democracy and due process that have become common in our Party; Pressured the leadership to fully implement the Forde Report...",
    body: <div>
      <p>As a working-class man of Bangladeshi heritage, whose father campaigned against the National Front, I am a committed anti-racist bringing my commitments to equality and justice onto the NEC since I was first elected in 2020. I have:</p>

      <ul>
        <li>Opposed the selection stitch-ups and crackdowns on democracy and due process that have become common in our Party</li>
        <li>Pressured the leadership to fully implement the Forde Report</li>
        <li>Opposed all discrimination including Islamophobia, anti-semitism, homophobia, sexism, and transphobia without fear or favour.</li>
        <li>Spoken out against the indiscriminate killing of innocents in the Israel-Palestine conflict and founded the Labour For a Ceasefire Now campaign.</li>
        <li>Regularly reported back via online calls, articles and social media</li>
        <li>Worked collaboratively to introduce new equalities structures, including a BAME Members’ Structure which the leadership ditched.</li>
      </ul>

      <p>With a General Election looming, I will work to deliver an election-winning Party which will change the lives of millions for the better</p>.

      <p>That means supporting popular policies like a Green New Deal, public ownership, repealing anti-trade union laws, advancing migrants’ rights, defending hum</p>an rights across the world and internationalist solidarity.

      <p>I am proud to be supported by the Centre-Left Grassroots Alliance and Momentum. Please also support Jess Barnard, Gemma Bolton, and Yasmine Dar.</p>
    </div>,
  },
  {
    position: "CLP SECTION",
    slug: "yasmine-dar",
    handle: "Yasmine_Dar",
    name: "Yasmine Dar",
    imgUrl: "/img/yasmine-dar.png",
    labourMembershipNumber: "L1173052",
    blurb: "I’m standing for re-election to the NEC, to continue as a strong voice advocating for members, the lifeblood of our Party. Throughout my tenure, I’ve championed transformative economic policies and a foreign policy rooted in internationalism, seeking peace, justice, and human rights. I actively promote Party democracy, including dynamic, inclusive equalities structures, implementation of the Forde report...",
    body: <div>
      <p>I’m standing for re-election to the NEC, to continue as a strong voice advocating for members, the lifeblood of our Party.</p>

      <p>Throughout my tenure, I’ve championed transformative economic policies and a foreign policy rooted in internationalism, seeking peace, justice, and human rights.</p>

      <p>I actively promote Party democracy, including dynamic, inclusive equalities structures, implementation of the Forde report and a strong trade union link. I’ve tirelessly advocated for transparent and fair disciplinary processes founded on natural justice.</p>

      <p>Empowering members is a focal point of my efforts, including advocating for their right to select preferred candidates. As a BAME woman, social worker, anti-racist activist, and Councillor, my experiences fuel my determination to fight for a Labour government that transforms society. A crucial general election is on the horizon, and victory is imperative.</p>

      <p>In the face of escalating living costs, heightened inequalities, and the ongoing impacts of Covid, our campaign against this government must intensify. I endorse vital campaigns to defend our NHS, for urgent action on climate change. I maintain a strong commitment to anti-war and anti-racist activism.</p>

      <p>Please also nominate Jess Barnard, Gemma Bolton and Mish Rahman. Together, we can build a more just and equitable future for our nation.</p>
    </div>,
  },
  {
    position: "YOUTH REP",
    slug: "india-rees",
    handle: null,
    name: "India Rees",
    imgUrl: "/img/india-rees.png",
    labourMembershipNumber: "L1449629",
    blurb: "Young people need a Labour government. With that prospect in reach, here’s where I stand: As a teacher, I know first-hand that we need an education system that is free and accessible from cradle to grave. As a young worker and trade union representative in Young Labour, I am committed to working with the labour movement to organise young members. I have worked on campaigns centring women’s safety at...",
    body: <div>
      <p>Young people need a Labour government. With that prospect in reach, here’s where I stand:</p>

      <ul>
        <li>As a teacher, I know first-hand that we need an education system that is free and accessible from cradle to grave.</li>
        <li>As a young worker and trade union representative in Young Labour, I am committed to working with the labour movement to organise young members.</li>
        <li>I have worked on campaigns centring women’s safety at local and national levels as part of the Misogyny is Hate campaign.</li>
        <li>I know every door counts. We need our youth structures to be fully geared towards winning the general election.</li>
        <li>Our Party must do better on trans rights, something for which I will fearlessly advocate.</li>
      </ul>

      <p>I’ll fight for policies young people care about:</p>

      <ul>
        <li>Affordable and accessible housing, including support for renters and transport costs.</li>
        <li>Labour’s commitment to introduce the New Deal for Working People within 100 days of government, delivering the biggest upgrade in individual and collective employment rights in generations.</li>
        <li>Stamping out the shameful racism that exists inside and outside of our Party.</li>
        <li>A foreign policy guided by peace and international law – including a full and immediate ceasefire in Gaza, and a recognition of the State of Palestine.</li>
      </ul>
    </div>
  },
  { 
    position: "DISABLED REP",
    slug: "ellen-morrison",
    handle: null,
    name: "Ellen Morrison",
    imgUrl: "/img/ellen-morrison.png",
    labourMembershipNumber: "L1509954",
    blurb: <>
      <span><strong>Elected Unopposed</strong></span>
      <br />
      <br />
      <span>I’m proud to be serving my second term as the first-ever Disabled Members Representative. Re-elect me, and I’ll keep fighting for those issues both outside and within our party – working with trade unions and the Disabled people’s movement to win full, clear commitments from our Shadow Cabinet on the issues that most affect disabled people. Like many...</span>
    </>,
    body: <div>
      <p>I’m proud to be serving my second term as the first-ever Disabled Members Representative.</p>

      <p>Re-elect me, and I’ll keep fighting for those issues both outside and within our party – working with trade unions and the Disabled people’s movement to win full, clear commitments from our Shadow Cabinet on the issues that most affect disabled people.</p>

      <p>Like many disabled members, I also haven’t forgotten what we were promised in the Democracy Review.</p>

      <p>I won’t stop pushing for a national disabled members’ committee, elected by one member, one vote.</p>

      <p>I also want to ensure remote access to meetings remains, and is extended with provision for hybrid meetings at CLP level.</p>

      <p>Finally, I’ll do everything I can to ensure disabled people are elected to positions within our party. We must make more progress on seeing disabled people from a wide range of impairment groups become our next generation of MPs, councillors and devolved leaders.</p>

      <p>I have always stood for bringing the Disabled People’s movement into Labour.</p>

      <p>Re-elect me so I can continue my work defending the interests of both disabled members and disabled people more broadly, and to work with you to win the change we need to see.</p>
    </div>
  },
  {
    position: "WELSH REP",
    slug: "jackie-owen",
    handle: null,
    name: "Jackie Owen",
    imgUrl: "/img/jackie-owen.png",
    labourMembershipNumber: "L132480",
    blurb: <span>
      I'm a member of Wrexham CLP (previously Clwyd South CLP secretary), member of Welsh Women's Committee and Welsh Labour Executive Committee – I'm standing to ensure grassroots members...
      <hr classname="m-2" style={{margin: "20px 40px"}} />
      Rwy'n aelod o PLlE Wrecsam (cyn ysgrifennydd PLlE De Clwyd), yn aelod o Bwyllgor Merched Cymru a PGLC – rwy'n sefyll i sicrhau bydd gan aelodau llawr gwlad lais ar y PGC. Rwy'n nyrs...
    </span>,
    body: <div class="row p-0">
      <div class="col-sm no-gutters">
        <p>I'm a member of Wrexham CLP (previously Clwyd South CLP secretary), member of Welsh Women's Committee and Welsh Labour Executive Committee – I'm standing to ensure grassroots members have a real voice on the NEC.</p>

        <p>I'm a registered nurse, member of UNISON & UNITE and Secretary and Women's Officer of the Wales Co-operative Party Council. I'm an active member of SHA & SEA Cymru. I would bring a frontline, working-class perspective to the NEC highlighting the experiences of ordinary people after 14-years of Tory austerity. I support community wealth building and the sharing of wealth and power.</p>

        <p>Extending devolution, in government and within our Party, is a priority. I believe that the concentration of power within the NEC is not consistent with a member-led party.</p>

        <p>I am an experienced campaigner, organising the last 5 election campaigns in Clwyd South, doubling the number of Labour County Councillors in 2022.</p>

        <p>I have extensive policy making experience including representing CLPs on the committee that drafted the 2021 Welsh Labour Senedd Manifesto</p>

        <p>Given the fantastic General Election result & upcoming Senedd Election, my priority will be to ensure Labour Policies that will make a difference to working class people's lives are implemented as soon as possible.</p>
      </div>
      <div class="col-sm">
        <p>Rwy'n aelod o PLlE Wrecsam (cyn ysgrifennydd PLlE De Clwyd), yn aelod o Bwyllgor Merched Cymru a PGLC – rwy'n sefyll i sicrhau bydd gan aelodau llawr gwlad lais ar y PGC.</p>

        <p>Rwy'n nyrs gofrestredig, yn aelod o UNSAIN ac UNITE ac yn Ysgrifennydd a Swyddog Menywod Cyngor Plaid Gydweithredol Cymru. Rwy'n aelod gweithgar o SHA & SEA Cymru. Byddwn yn dod â phersbectif rheng flaen, dosbarth gweithiol i'r PGC gan dynnu sylw at brofiadau pobl gyffredin ar ôl 14 mlynedd o lymder Torïaidd. Rwy'n cefnogi adeiladu cyfoeth cymunedol a rhannu cyfoeth a phŵer.</p>

        <p>Mae ehangu datganoli, mewn llywodraeth ac yn y Blaid, yn flaenoriaeth. Credaf nad yw cydbwysedd pŵer y PGC yn gyson â phlaid a arweinir gan aelodau.</p>

        <p>Rwy'n ymgyrchydd profiadol, gan drefnu'r 5 ymgyrch etholiadol ddiwethaf yn Ne Clwyd, gan ddyblu'r nifer o Gynghorwyr Sirol Llafur yn 2022.</p>

        <p>Mae gennyf brofiad helaeth o lunio polisi, gan gynrychioli PLlE ar y pwyllgor a ddrafftiodd Faniffesto Senedd Llafur Cymru 2021.</p>

        <p>O ystyried canlyniad gwych yr Etholiad Cyffredinol a'r Etholiad Senedd sydd i ddod, fy mlaenoriaeth fydd sicrhau bod Polisïau Llafur yn gwneud gwahaniaeth i fywydau pobl dosbarth gweithiol ac yn cael eu rhoi ar waith cyn gynted â phosibl.</p>
      </div>
    </div>
  },
  {
    position: "LOCAL GOVERNMENT REP",
    slug: "minesh-parekh",
    handle: null,
    name: "Minesh Parekh",
    imgUrl: "/img/minesh-parekh.png",
    labourMembershipNumber: "L1230833",
    blurb: "I'm Minesh, a Labour and Co-operative Councillor and portfolio holder on Sheffield City Council. I've worked with colleagues to support Community Wealth Building policies, mitigate the cost-of-living crisis, and to centre climate justice in our work. Despite difficult funding settlements, I'm proud of the decisions taken to ringfence services and deliver for residents. I'm also proud that...",
    body: 
      <div>
        <p>I'm Minesh, a Labour and Co-operative Councillor and portfolio holder on Sheffield City Council.</p>

        <p>I've worked with colleagues to support Community Wealth Building policies, mitigate the cost-of-living crisis, and to centre climate justice in our work. Despite difficult funding settlements, I'm proud of the decisions taken to ringfence services and deliver for residents.</p>

        <p>I'm also proud that Sheffield was the first council to support an immediate ceasefire in Gaza; it is right that councils champion our communities' voices and stand up for peace and justice.</p>

        <p>As your NEC Rep, my priority would be increasing local government funding.</p>

        <p>The Tory government forced councils to make unbearable cuts, something we all know must come to an end. There's an alternative future within reach: local authorities building council housing, creating good green jobs, and providing publicly-owned buses and trams. But we won't get there with one arm tied behind our backs.</p>

        <p>Our general election landslide has given us a mandate to transform our country. The new Labour government can and must end fourteen years of austerity, lift the burden from our councils, and transform local government.</p>

        <p>I hope to win your support and be a voice for councillors and councils on the NEC.</p>
    </div>
  },
  {
    position: "LOCAL GOVERNMENT REP",
    slug: "soraya-adejare",
    handle: null,
    name: "Soraya Adejare",
    imgUrl: "/img/soraya-adejare.png",
    labourMembershipNumber: "L0126481",
    blurb: "Having grown up in poverty amid austerity and far-right politics, the opportunities afforded me were minimal. I don’t want this replicated for anyone and throughout my 9 years as a councillor in Hackney, I’ve worked tirelessly for the betterment of all. As an NEC representative I will continue to do so, also fighting for Labour to enable councils to build social housing on the scale needed for our communities...",
    body:
      <div>
        <p>Having grown up in poverty amid austerity and far-right politics, the opportunities afforded me were minimal. I don’t want this replicated for anyone and throughout my 9 years as a councillor in Hackney, I’ve worked tirelessly for the betterment of all. As an NEC representative I will continue to do so, also fighting for Labour to enable councils to build social housing on the scale needed for our communities.</p>

        <p>I will be strident in making calls for our incoming Labour Government to increase local government funding to ensure we can readily meet statutory obligations and restore services.</p>

        <p>I have been consistent in calling for a ceasefire in Gaza. I believe that Labour councillors do have a responsibility to our residents to strive for peace and that’s why I support the right of councils to take principled decisions when supporting campaigns for peace.</p>

        <p>I’m a single parent and supporter of the Single Parent Rights campaign. I brought it to Council and called for status recognition within the Equalities Act. As a current scrutiny chair, I’ve also worked collaboratively on our ongoing response to the Child Q case, our greening agenda and housing provision.</p>
    </div>
  },
  {
    position: "EASTERN",
    slug: "shahid-nadeem",
    handle: null,
    name: "Shahid Nadeem",
    imgUrl: "/img/shahid-nadeem.png",
    labourMembershipNumber: "L1811877",
    blurb: "I’m CLP Secretary and treasurer of Unite legal workers’ branch and an active member of the Society of Labour Lawyers, which provides legal and policy advice to the Labour Party. If re-elected to the NPF, I would like to utilise my experience as a Member of Unison, SME4Labour Executive board member,  founder of Pakistanis for Labour, vice chair of the South Essex Cooperative Party and Western...",
    body: <div>
      <p>I’m CLP Secretary and treasurer of Unite legal workers’ branch and an active member of the Society of Labour Lawyers, which provides legal and policy advice to the Labour Party. If re-elected to the NPF, I would like to utilise my experience as a Member of Unison, SME4Labour Executive board member,  founder of Pakistanis for Labour, vice chair of the South Essex Cooperative Party and Western East Anglia Coop Party Council, to develop strong regional policy and engaging members.</p>

      <p>I’ll lay the groundwork for a bold agenda on the economy, jobs, health, education, housing, peace, and human rights; this will transform our country. It has been an honour to be your NPF Rep for the past two years. Apart from contributing to the Safe Communities commission, I also worked for amendments including £15 wage, housing, a Green New Deal, and Public ownership. I stood on a platform of engaging with members and putting members’ views at the heart of Labour’s policy-making and I will continue to do so.</p>

      <p>I’m asking you to vote for me to continue to fight for you and your priorities and support other grassroots candidates Rachel Garnham, Bryn Griffiths, Maxine Sadza and Alex Small (Youth Rep).</p>
    </div>,
  },
  {
    position: "EASTERN",
    slug: "bryn-griffiths",
    handle: null,
    name: "Bryn Griffiths",
    imgUrl: "/img/bryn-griffiths.png",
    labourMembershipNumber: "L1949828",
    blurb: "I will always prioritise giving a voice to ordinary members and making myself available to the Constituency Labour Parties (CLPs).  I will act as an advocate of the radical policies which have been passed by our Conference. I will support the Trade Unions in making sure that we are bold in our implementation of the New Deal for Working People.  Labour’s Green Prosperity Plan should be the central...",
    body: <div>
      <p>I will always prioritise giving a voice to ordinary members and making myself available to the Constituency Labour Parties (CLPs).  I will act as an advocate of the radical policies which have been passed by our Conference.</p>

      <p>I will support the Trade Unions in making sure that we are bold in our implementation of the New Deal for Working People.  Labour’s Green Prosperity Plan should be the central plank of our policy and I will always advocate a radical approach that is proportionate to the challenge the planet faces. On economic policy I want our party to back our public services e.g. I will always argue for the rail service to return to the public sector. I will stand with the public sector unions in their efforts to remove the privateers from the National Health Service and Social Care. I will abide by Labour Conference policy passed by an overwhelming majority that we should introduce proportional representation.</p>

      <p>I am standing with Shahid Nadeem, Maxine Sadza, Rachel Garnham, Bryn Griffiths, and Alex Small (Youth position) as a Centre Left Grassroots Alliance candidate. I also support Momentum and sit on the Campaign for Labour Party Democracy Executive.</p>
    </div>,
  },
  {
    position: "EASTERN",
    slug: "rachel-gamham",
    handle: null,
    name: "Rachel Gamham",
    imgUrl: "/img/rachel-gamham.png",
    labourMembershipNumber: "A777413",
    blurb: "I am an experienced, committed campaigner serving seven years on the National Policy Forum, 20 plus years in local Labour positions and previously three years on the National Executive Committee. I am a UCU activist and strong supporter of Labour’s trade union link. Labour needs a policy programme that will win elections and transform lives; seriously addressing the cost of living and climate crises...",
    body: <div>
      <p>I am an experienced, committed campaigner serving seven years on the National Policy Forum, 20 plus years in local Labour positions and previously three years on the National Executive Committee. I am a UCU activist and strong supporter of Labour’s trade union link.</p>

      <p>Labour needs a policy programme that will win elections and transform lives; seriously addressing the cost of living and climate crises; redistributing wealth and rebuilding public services.</p>

      <p>On the NPF Public Services Policy Commission I have championed NPF submissions from Eastern Region CLPs including:</p>

      <ul>
        <li>NHS investment not privatisation;</li>
        <li>Local accountability of schools; extending Free School Meals;</li>
        <li>Increased pay for health, social care, and education workers across the public sector.</li>
      </ul>

      <p>I have promoted reversal of Tory attacks on civil liberties; and listening to women by advocating policy made by Labour’s Women’s Conference. At the NPF in Nottingham I put forward amendments that made a difference, including a commitment to international law in Palestine.</p>

      <p>I want to continue to promote this platform on the NPF, and work towards a more democratic, transparent and inclusive policy-making process, recognising the sovereignty of annual conference.</p>

      <p>Members matter.</p>

      <p>Please also support other grassroots candidates Bryn Griffiths, Shahid Nadeem, Maxine Sadza and Alex Small (Youth Rep).</p>
    </div>,
  },
  {
    position: "EASTERN",
    slug: "maxine-sadza",
    handle: null,
    name: "Maxine Sadza",
    imgUrl: "/img/maxine-sadza.png",
    labourMembershipNumber: "L1431770",
    blurb: "We’ve seen the effects of Tory austerity amongst the most marginalised in our society, leading to increased poverty, more food banks, and poverty wages for public sector workers. I’m currently CLP Treasurer, a retired member of Unite the Union, and have been Women’s, BAME, and Policy Officer. I was the Equalities Rep for my workplace whilst working for the NHS as a Biomedical Scientist. I brought up...",
    body: <div>
      <p>We’ve seen the effects of Tory austerity amongst the most marginalised in our society, leading to increased poverty, more food banks, and poverty wages for public sector workers.</p>

      <p>I’m currently CLP Treasurer, a retired member of Unite the Union, and have been Women’s, BAME, and Policy Officer. I was the Equalities Rep for my workplace whilst working for the NHS as a Biomedical Scientist. I brought up my two sons as a single parent. I am a grassroots Community activist involved in Acorn the Union. I help to set  up a local cultural community festival and am the first BAME woman to be elected as a Councillor to Southend City Council. I stood to be a councillor to be a voice for the voiceless and to represent minority ethnic groups in my community.</p>

      <p>I pledge to represent members’ views, work to ensure that democracy is upheld, and champion the New Deal for Working people and Labour’s Green Prosperity plan on the NPF. I will also oppose increased privatisation in our Health service.</p>

      <p>I’m Proud to be supported by the Centre-left Grassroots alliance and Momentum and I urge you to support Shahid Nadeem, Rachel Garnham, Bryn Griffiths and Alex Small (youth position).</p>
    </div>,
  },
  {
    position: "EASTERN (YOUTH REP)",
    slug: "alex-small",
    handle: null,
    name: "Alex Small",
    imgUrl: "/img/alex-small.png",
    labourMembershipNumber: "L1733607",
    blurb: "I’m currently Southend West and Leigh’s Vice-Chair and have been a conference delegate twice, debating and voting on Labour Party policy. I’ve worked with charities and community organisations, seeing first hand, the suffering felt by those at the sharpest end of our broken economic model, including those experiencing homelessness and food insecurity. This has solidified for me that we need...",
    body: <div>
      <p>I’m currently Southend West and Leigh’s Vice-Chair and have been a conference delegate twice, debating and voting on Labour Party policy.</p>

      <p>I’ve worked with charities and community organisations, seeing first hand, the suffering felt by those at the sharpest end of our broken economic model, including those experiencing homelessness and food insecurity. This has solidified for me that we need an incoming Labour government to be bold in facing these grave challenges, to transform society in favour of working people, and tackle the climate crisis head on.</p>

      <p>I’ve stood shoulder to shoulder with workers on the picket lines, as they campaigned for a fairer deal. I will continue to advocate for properly funding our vital public services, keeping them away from private profiteers and improving the pay and conditions of our key workers.</p>

      <p>I believe strongly in resident-led decision making and will push for further devolution and decentralisation, putting power and investment into local communities.</p>

      <p>If elected, I’ll respect the mandate by engaging extensively with members across Eastern region,  listening to your views and priorities, and working to ensure they are represented in Labour policy.</p>

      <p>Please also support other grassroots candidates Bryn Griffiths, Shahid Nadeem, Maxine Sadza and Rachel Garnham.</p>
    </div>,
  },
  {
    position: "EAST MIDLANDS",
    slug: "fraser-mcguire",
    handle: null,
    name: "Fraser McGuire",
    imgUrl: "/img/fraser-mcguire.png",
    labourMembershipNumber: "L1823756",
    blurb: "I’m the Chair of Unite Hospitality East Midlands branch, and have been the Young Labour rep for the East Midlands since 2022. Growing up in rural Derbyshire I have seen the devastating effect of Tory austerity on our communities, with cuts to local government funding, social care, education and transport. I’ve organised with hundreds of Young Labour members and young workers; I know how desperately...",
    body: <div>
      <p>I’m the Chair of Unite Hospitality East Midlands branch, and have been the Young Labour rep for the East Midlands since 2022. Growing up in rural Derbyshire I have seen the devastating effect of Tory austerity on our communities, with cuts to local government funding, social care, education and transport. I’ve organised with hundreds of Young Labour members and young workers; I know how desperately change is needed.</p>

      <p>I will stand up for transformative policies like nationalisation of key services, and as a climate activist I believe we have a long way to go to tackle the coming environmental crisis.</p>

      <p>As a committed trade unionist, and vice-president of Derby Trades council I will fight to see the New Deal for Working People go as far as possible. We need to unshackle our trade unions by repealing all anti-union legislation from the last 40 years and we must commit to pushing through workers rights reforms in the first 100 days of a Labour government.</p>

      <p>I’m proud to be supported by the Centre- Left Grassroots Alliance, and Momentum and I urge you to vote for Liv Marshall (youth position).</p>
    </div>,
  },
  {
    position: "EAST MIDLANDS (YOUTH REP)",
    slug: "liv-marshall",
    handle: null,
    name: "Liv Marshall",
    imgUrl: "/img/liv-marshall.png",
    labourMembershipNumber: "L3108212",
    blurb: "As a young woman who has grown up knowing nothing other than the brutality of austerity, and the failing of my generation by 14 years of Tory negligence, I want nothing more than for an incoming Labour government to put forward a transformative, progressive policy agenda. For too long, the climate crisis has been on the back foot of British politics, with continued investment in North Sea oil and...",
    body: <div>
      <p>As a young woman who has grown up knowing nothing other than the brutality of austerity, and the failing of my generation by 14 years of Tory negligence, I want nothing more than for an incoming Labour government to put forward a transformative, progressive policy agenda.</p>

      <p>For too long, the climate crisis has been on the back foot of British politics, with continued investment in North Sea oil and gas fields and the delay in phasing out petrol and diesel cars all happening under Tory rule. As East Midlands Youth Rep on the NPF, I’d ensure our party is a global trailblazer for net zero in government, by reinstating the £28bn and supporting the aims of the Climate Education Bill such as integrating climate change into vocational training and exams.</p>

      <p>I’m a proud trade unionist, climate activist, and young labour member, and will bring these values to the table, to ensure that when we do get into government, the agenda is set around the future, a brighter tomorrow.</p>

      <p>I’m proud to be supported by the Centre- Left Grassroots Alliance, and Momentum and I urge you to vote for Fraser McGuire.</p>
    </div>,
  },
  {
    position: "LONDON",
    slug: "dave-levy",
    handle: null,
    name: "Dave Levy",
    imgUrl: "/img/dave-levy.png",
    labourMembershipNumber: "A006829",
    blurb: "I believe our Party needs to unite around our core values so that we can develop a policy programme which will make a real difference to people’s lives when we get into government. I will fight for a policy platform in the interests of the many, not the few, based on anti-poverty, pro-social justice and anti-racist principles. I’ll also place membership involvement at the heart of my work. My experience as...",
    body: <div>
      <p>I believe our Party needs to unite around our core values so that we can develop a policy programme which will make a real difference to people’s lives when we get into government.</p>

      <p>I will fight for a policy platform in the interests of the many, not the few, based on anti-poverty, pro-social justice and anti-racist principles. I’ll also place membership involvement at the heart of my work.</p>

      <p>My experience as an economist, IT professional, trade unionist, and civil rights campaigner would mean I am very well-placed for the Economy, Culture, or Democracy commissions.</p>

      <p>I have been a member of the Labour Party for 50 years and have held various positions with it.</p>

      <p>I am President of my GMB branch, trained in employment law; I represent & organise members in their workplace.</p>

      <p>I am a member/subscriber to Amnesty, Liberty, the Open Rights Group, and the Labour Movement for Europe. I am active in Another Europe and CLPD.</p>

      <p>I will work hard, represent you, and remain fully open and transparent.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA), please also nominate and vote for Rathi Guhadasan, Pat Quigley, Aydin Dikerdem, and India Burgess (Youth Position).</p>
    </div>,
  },
  {
    position: "LONDON",
    slug: "aydin-dikerdem",
    handle: null,
    name: "Aydin Dikerdem",
    imgUrl: "/img/aydin-dikerdem.png",
    labourMembershipNumber: "L1217068",
    blurb: "The next Labour government is going to inherit a deeply challenging economic climate, but it’ll also be faced with a nation desperate for change after 14 years of Tory austerity. I think the role of the NPF is to be an engine for ambition during this period, that tests and offers ideas and solutions from the membership, affiliates and unions. As a local councillor and Cabinet Member for Housing...",
    body: <div>
      <p>The next Labour government is going to inherit a deeply challenging economic climate, but it’ll also be faced with a nation desperate for change after 14 years of Tory austerity. I think the role of the NPF is to be an engine for ambition during this period, that tests and offers ideas and solutions from the membership, affiliates and unions.</p>

      <p>As a local councillor and Cabinet Member for Housing I’m acutely aware of the challenges facing the public sector but also the radical alternatives that can be achieved with bold thinking. I joined the Labour Party in 2013 as austerity was biting and the housing crisis was deepening yet saw how workable pragmatic left-wing alternatives were side-lined. I’ve seen first-hand the intersection between policy and campaigns when working as an organiser for the New Economics Foundation, which has given me a strong grounding in the politics and national conversation around policy. And of course I’m now responsible for developing and implementing policy at the local level in a swing Council.</p>

      <p>I’m glad to be backed by the Centre Left Grassroots Alliance and would ask you to also nominate Rathi Guhadasan, Dave Levy, Pat Quigley, and India Burgess (Youth Position).</p>
    </div>,
  },
  {
    position: "LONDON",
    slug: "rathi-guhadasan",
    handle: null,
    name: "Rathi Guhadasan",
    imgUrl: "/img/rathi-guhadasan.png",
    labourMembershipNumber: "L1362546",
    blurb: "I’m the CLP secretary for Brent East, Vice Chair for Kingsbury ward and served as ward organiser in my previous ward. I have attended the Labour national conference in 2022 and 2023, as CLP and Socialist Health Association delegate. I have participated in writing motions at ward level and for the SHA. As CLP secretary, I am familiar with the Labour Party rule book and allied resources. I am a national...",
    body: <div>
      <p>I’m the CLP secretary for Brent East, Vice Chair for Kingsbury ward and served as ward organiser in my previous ward. I have attended the Labour national conference in 2022 and 2023, as CLP and Socialist Health Association delegate. I have participated in writing motions at ward level and for the SHA. As CLP secretary, I am familiar with the Labour Party rule book and allied resources. I am a national SHA Vice-Chair and London Branch secretary.</p>

      <p>I’m a participating member of Doctors in Unite and a qualified paediatrician with a background in international public health and the international development and humanitarian sectors. I have a keen interest in many areas of policy, particularly health, and would like to see a more integrated, intersectoral approach to public health policy. We must also see the New Deal for Working people delivered in full as well as a proper Green New Deal. If elected, I will endeavour to represent the grassroots of our Labour movement and be accountable and transparent to members.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA). Please also nominate and vote for Dave Levy, Pat Quigley, Aydin Dikerdem, and India Burgess (Youth Position).</p>
    </div>,
  },
  {
    position: "LONDON",
    slug: "pat-quigley",
    handle: null,
    name: "Pat Quigley",
    imgUrl: "/img/pat-quigley.png",
    labourMembershipNumber: "L1505915",
    blurb: "The Labour Party must enable change for the better for the lives of current and future generations of working-class people in this country. I will work hard for policies that address entrenched poverty, ensure secure, decent housing for all and high quality educational, health and public services that deliver good outcomes for everyone, creating a more equitable and just society. A lifelong Labour Party...",
    body: <div>
      <p>The Labour Party must enable change for the better for the lives of current and future generations of working-class people in this country. I will work hard for policies that address entrenched poverty, ensure secure, decent housing for all and high quality educational, health and public services that deliver good outcomes for everyone, creating a more equitable and just society.</p>

      <p>A lifelong Labour Party supporter, I joined 8 years ago and have been active since in my local Labour party, previously Secretary of my ward branch for 5 years and now the CLP’s Women’s Officer and Secretary of Women’s branch.</p>

      <p>I worked in Education for over 40 years, as a classroom and headteacher; I’m diplomatic and experienced at working with a breadth of views. I have an interest in SEND policy and issues affecting families and children. I am a retired member of the NEU.</p>

      <p>I’m a community campaigner, fighting for social rent housing and the Right to Food.</p>

      <p>I’m a member of several Socialist Societies, including SHA and I sit on the National Executive of the Socialist Education Association.</p>

      <p>I’m supported by the CLGA, please nominate me and Rathi Guhadasan, Aydin Dikerdem, Dave Levy, and India Burgess (Youth Position).</p>
    </div>,
  },
  {
    position: "LONDON (YOUTH REP)",
    slug: "india-burgess",
    handle: null,
    name: "India Burgess",
    imgUrl: "/img/india-burgess.png",
    labourMembershipNumber: "L1928388",
    blurb: "Over the coming years, the Labour Party should nationalise key public services including energy companies, utilities and public transport; support devolution and investment in community services and spaces; and introduce a wealth tax to reduce inequality. It is also critical that Labour calls and advocates for a permanent and immediate ceasefire in Gaza. Not only are these policies popular but...",
    body: <div>
      <p>Over the coming years, the Labour Party should nationalise key public services including energy companies, utilities and public transport; support devolution and investment in community services and spaces; and introduce a wealth tax to reduce inequality. It is also critical that Labour calls and advocates for a permanent and immediate ceasefire in Gaza.</p>

      <p>Not only are these policies popular but they are necessary to build stronger, happier and greener communities. They are the policies I will fight for as your representative on the National Policy Forum. </p>

      <p>Since university, the policies impacting every aspect of our lives have interested and inspired me. For the past five years, I’ve been volunteering, advocating and working in policy from the local level to the international stage. Through all of this, it’s become clear that the UK needs to be bold and ambitious to face multiple environmental and social crises.</p>

      <p>I’m standing for Labour’s National Policy Forum to propose, debate and fight for policies that can deliver a future that’s not just fair but thriving.</p>

      <p>I’m supported by the CLGA, please nominate me as well as Rathi Guhadasan, Aydin Dikerdem, Dave Levy, and Pat Quigley.</p>
    </div>,
  },
  {
    position: "NORTHERN",
    slug: "david-ray",
    handle: null,
    name: "David Ray",
    imgUrl: "/img/david-ray.png",
    labourMembershipNumber: "L1488264",
    blurb: "My name is David Ray, and I am a Labour Borough Councillor in Darlington. As well as this, I work in Telecommunications, and I am an Industrial Relations Rep for the Communication Workers Union. I believe this puts me in a great position to be able to represent a large portion of Labour’s support base and membership. I work daily in my role as a councillor and a trade unionist, to shout about the values so many...",
    body: <div>
      <p>My name is David Ray, and I am a Labour Borough Councillor in Darlington. As well as this, I work in Telecommunications, and I am an Industrial Relations Rep for the Communication Workers Union. I believe this puts me in a great position to be able to represent a large portion of Labour’s support base and membership. I work daily in my role as a councillor and a trade unionist, to shout about the values so many Labour Party members hold dear.</p>

      <p>I strive to champion our party, and the election of a Labour Government is what drives me. I want to take up the role, so I can play a bigger part in how we shape this party, so we can make a difference to people’s lives in the North of England and the rest of the country, with progressive and transformative policies on things such as enhancing workers’ rights, ending poverty/economic inequality, building more social housing, community wealth building, and addressing climate change.</p>

      <p>I’m proud to be supported by the Centre- Left Grassroots Alliance, CLPD, and Momentum and I urge you to nominate and vote for Hannah Cousins, Rochelle Charlton-Laine, Samantha Townsend and Joshua Freestone (youth position).</p>
    </div>,
  },
  {
    position: "NORTHERN",
    slug: "samantha-townsend",
    handle: null,
    name: "Samantha Townsend",
    imgUrl: "/img/samantha-townsend.png",
    labourMembershipNumber: "L1462687",
    blurb: "My name is Samantha and I am a serving councillor representing Shildon and Dene Valley, a community in South West Durham which has suffered terribly from over a decade of Tory decline. I’m a socialist and a trade unionist, with a keen interest in education as well as the rights of disabled people and their carers. As the Labour Party enters government I’m keen to ensure policy reflects the needs of communities...",
    body: <div>
      <p>My name is Samantha and I am a serving councillor representing Shildon and Dene Valley, a community in South West Durham which has suffered terribly from over a decade of Tory decline.</p>

      <p>I’m a socialist and a trade unionist, with a keen interest in education as well as the rights of disabled people and their carers. As the Labour Party enters government I’m keen to ensure policy reflects the needs of communities like the one I represent to ensure we can never fall to the Tories across the Red Wall again.</p>

      <p>Having held many positions within the local constituency party, including secretary and chair, I recognise and value the voice of members and truly believe that we are at our best when we build from the grassroots. As a councillor I know that there is an art to compromise, I am pragmatic and reasonable but will always make a robust case for my values.</p>

      <p>I am proud to be supported by the CLGA and Momentum. Hannah Cousins, David Ray, Rochelle Charlton-Laine, and Joshua Freestone (Youth Position). We’ll be a strong team working together to ensure that the NPF considers a wide range of policy platforms and remains accountable to members.</p>
    </div>,
  },
  {
    position: "NORTHERN",
    slug: "rochelle-charlton-laine",
    handle: null,
    name: "Rochelle Charlton-Laine",
    imgUrl: "/img/rochelle-charlton-laine.png",
    labourMembershipNumber: "L1210405",
    blurb: "I have witnessed first-hand many atrocities within the education system as a teacher under this tory government. I’ve been on strike to demand better pay and conditions and I give my full support to those in similar circumstances. As a county councillor, I’ve fought to support our poorest families that are being left behind; to bring them with us, we need a more equitable distribution of wealth. I also...",
    body: <div>
      <p>I have witnessed first-hand many atrocities within the education system as a teacher under this tory government. I’ve been on strike to demand better pay and conditions and I give my full support to those in similar circumstances.</p>

      <p>As a county councillor, I’ve fought to support our poorest families that are being left behind; to bring them with us, we need a more equitable distribution of wealth. I also ensure wide, transparent communication in my role as labour Group Communications & Engagement Officer.</p>

      <p>In the past two years as an NPF rep, I’ve helped shape policies to ensure better public provision of dental care and stronger support for SEN students; I hope a Labour government will go even further.</p>

      <p>I hold myself and others accountable whilst evaluating how best to move forward successfully, because I believe listening to grassroots voices should be central to any worthwhile policy development. This will also be helped by passion for equality, diversity, and inclusivity. Working together we achieve more than we achieve alone.</p>

      <p>I’m proud to be supported by the Centre- Left Grassroots Alliance and Momentum and urge you to nominate and vote for Hannah Cousins, David Ray, Samantha Townsend, and Joshua Freestone (youth position).</p>
    </div>,
  },
  {
    position: "NORTHERN",
    slug: "hannah-cousins",
    handle: null,
    name: "Hannah Cousins",
    imgUrl: "/img/hannah-cousins.png",
    labourMembershipNumber: "L1424316",
    blurb: "On the NPF, I hope to promote member democracy, push for transformative socialist policies and ensure Labour in government enacts meaningful, long-lasting change. I’ve been active in the Labour Party for 9 years; mobilising and empowering young members as CLP Youth Officer, organising canvassing sessions as campaign co-ordinator and currently as my local branch chair. As a committed trade unionist...",
    body: <div>
      <p>On the NPF, I hope to promote member democracy, push for transformative socialist policies and ensure Labour in government enacts meaningful, long-lasting change.</p>

      <p>I’ve been active in the Labour Party for 9 years; mobilising and empowering young members as CLP Youth Officer, organising canvassing sessions as campaign co-ordinator and currently as my local branch chair.</p>

      <p>As a committed trade unionist, serving as secretary for my Unite branch and previously Women’s Officer, I know how invaluable the link between the trade unions and Labour is. Having worked in the hospitality and transport industries and witnessing how our rights at work have been eroded under this Conservative government, it is crucial that an incoming Labour government delivers a New Deal for Working People and a Green Transition; pushing for these will be my top priorities.</p>

      <p>It’s vital for CLPs across the region to be engaged with and contributing to our policy forum, and to be updated regularly by their representatives. I’ll always be accessible and transparent if I am elected.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA). Please also nominate and vote for Samantha Townsend, David Ray, Rochelle Charlton-Laine and Joshua Freestone (Youth Position).</p>
    </div>,
  },
  {
    position: "NORTHERN (YOUTH REP)",
    slug: "josh-freestone",
    handle: null,
    name: "Josh Freestone",
    imgUrl: "/img/josh-freestone.png",
    labourMembershipNumber: "L1783553",
    blurb: "I’m Josh Freestone, a proud member of City of Durham CLP and the Northern Rep on the national labour students committee. I am delighted to be standing for NPF North Youth representative. As the Labour Party approaches Government, we have a historic opportunity to use state power and set a progressive policy agenda. The NPF will become a critical organ of party democracy and the fight for democratic...",
    body: <div>
      <p>I’m Josh Freestone, a proud member of City of Durham CLP and the Northern Rep on the national labour students committee. I am delighted to be standing for NPF North Youth representative.</p>

      <p>As the Labour Party approaches Government, we have a historic opportunity to use state power and set a progressive policy agenda. The NPF will become a critical organ of party democracy and the fight for democratic socialist programs. After 40 years of failed neoliberal orthodoxy and an increasingly vicious Conservative party, a transformative Labour Government has never been so needed.</p>

      <p>On the NPF, I would champion Labour’s New Deal for Working People which will win rights for workers across the economy, fundamentally shifting the balance of power in the workplace. I will also push for a just and green transition in the face of the climate crisis and a compassionate immigration policy, defending the rights of migrants against the Tories’ despicable Rwanda plan.</p>

      <p>It would be an immense privilege to have your support.</p>

      <p>I’m proud to be supported by the Centre- Left Grassroots Alliance and Momentum and urge you to nominate and vote for Hannah Cousins, Rochelle Charlton-Laine, David Ray and Samantha Townsend.</p>
    </div>,
  },
  {
    position: "NORTH WEST",
    slug: "john-bowden",
    handle: null,
    name: "John Bowden",
    imgUrl: "/img/john-bowden.png",
    labourMembershipNumber: "L0147273",
    blurb: "Great policymaking starts with a bold and creative vision of the society we want to be part of. It’s followed by building a policy framework which takes a unified approach to environmental, social and economic justice. If elected I will bring a socialist vision to the NPF, backed up by policy expertise as both a qualified environmental and social value practitioner, and my experience as a homebuilding...",
    body: <div>
      <p>Great policymaking starts with a bold and creative vision of the society we want to be part of. It’s followed by building a policy framework which takes a unified approach to environmental, social and economic justice.</p>

      <p>If elected I will bring a socialist vision to the NPF, backed up by policy expertise as both a qualified environmental and social value practitioner, and my experience as a homebuilding sustainability director.</p>

      <p>In today’s world, climate justice needs to be factored into every policy decision. I can bring climate expertise into the Forum together with an understanding of business.</p>

      <p>Labour’s policies should be based on solidarity – fighting poverty and oppression at home and abroad. The brutal occupations of Ukraine and Palestine must both be condemned in the strongest terms and a consistent approach adopted when responding to countries that break international law.</p>

      <p>During my time as a CLP Secretary I learned that information is key to engaging members and inspiring activity – I commit to keeping members well informed if elected.</p>

      <p>I’m proud to be supported by the Centre-Left Grassroots Alliance and Momentum and urge you to support Fianna Hornby, Evangeline Walker and Antonia Shipley (youth position).</p>
    </div>,
  },
  {
    position: "NORTH WEST",
    slug: "evangeline-walker",
    handle: null,
    name: "Evangeline Walker",
    imgUrl: "/img/evangeline-walker.png",
    labourMembershipNumber: "L1965535",
    blurb: "I joined the Labour Party because I wanted to help build a movement that develops bold solutions to the issues facing the country. In my CLP, I regularly contribute to discussions on the key policy challenges with a particular interest in housing and equalities. At meetings of our Women’s Branch, I’ve heard from women from all walks of life who’ve fought injustice and misogyny, and learned from their...",
    body: <div>
      <p>I joined the Labour Party because I wanted to help build a movement that develops bold solutions to the issues facing the country.</p>

      <p>In my CLP, I regularly contribute to discussions on the key policy challenges with a particular interest in housing and equalities. At meetings of our Women’s Branch, I’ve heard from women from all walks of life who’ve fought injustice and misogyny, and learned from their campaigns fighting against these issues. Chairing my university Labour Club has provided me with the organisation and communication skills required for the NPF.</p>

      <p>As a committed member of Unite, I’ve stood on picket lines alongside striking workers: if elected, I’ll be uncompromising in my support for our policy offer for workers and in particular, delivering the New Deal for Working People in full.</p>

      <p>If elected, I will always put members first, ensuring that they’re able to hold me accountable. It would be a privilege to represent you on the NPF.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance and urge you to support Fianna Hornby, John Bowden and Antonia Shipley (youth position).</p>
    </div>,
  },
  {
    position: "NORTH WEST",
    slug: "fianna-hornby",
    handle: null,
    name: "Fianna Hornby",
    imgUrl: "/img/fianna-hornby.png",
    labourMembershipNumber: "L1330132",
    blurb: "I’m the proud Councillor for Gorse Hill and Cornbrook in Trafford – home to the first planned industrial estate in the world. Representing a ward with such great industrial heritage has only cemented my belief that the UK needs a Green New Deal to both address environmental challenges we face and ensure unionised Green jobs to support a just transition. After growing up in Preston and seeing...",
    body: <div>
      <p>I’m the proud Councillor for Gorse Hill and Cornbrook in Trafford – home to the first planned industrial estate in the world. Representing a ward with such great industrial heritage has only cemented my belief that the UK needs a Green New Deal to both address environmental challenges we face and ensure unionised Green jobs to support a just transition.</p>

      <p>After growing up in Preston and seeing the area flourish, I’ve taken inspiration from the socialist council leadership there. They have put Community Wealth Building at the heart of the Council. It’s been a pleasure to see more money staying embedded in the area. I believe these policies should be a key priority for a future Labour Government.</p>

      <p>I will do everything in my power to enable a Labour government. We then need to expand public ownership, secure better rights for workers, provide a decent education for all ages and incomes, protect our wonderful NHS, expand free childcare to support families and build more council housing across the UK.</p>

      <p>I am proud to be supported by the Centre- Left Grassroots Alliance and Momentum and urge you to support John Bowden, Evangeline Walker and Antonia Shipley (youth position).</p>
    </div>,
  },
  {
    position: "NORTH WEST (YOUTH REP)",
    slug: "antonia-shipley",
    handle: null,
    name: "Antonia Shipley",
    imgUrl: "/img/antonia-shipley.png",
    labourMembershipNumber: "L1948862",
    blurb: "My life was changed by labour power in Manchester, from a council house in Ashton-Under-Lyne, losing my father and caring for my mum, I believed that aspirations were never really for “kids like me”, but the incredible work of Burnham and the council enabled me to travel to a top sixth form college, which ultimately brought me to university and has enriched me in ways I couldn’t have dreamt of. I’ve experienced...",
    body: <div>
      <p>My life was changed by labour power in Manchester, from a council house in Ashton-Under-Lyne, losing my father and caring for my mum, I believed that aspirations were never really for “kids like me”, but the incredible work of Burnham and the council enabled me to travel to a top sixth form college, which ultimately brought me to university and has enriched me in ways I couldn’t have dreamt of.</p>

      <p>I’ve experienced what Labour in power can do for young people, and I hope to have a role in expanding that power, ensuring that “kids like me” are able to realise their aspirations. The voices of young people, the next generation, will be indispensable at the next election.</p>

      <p>We need to stand united across the North West, pushing for better, more affordable transport and housing for young people, advocating for more investment in our education system, and fundamentally better opportunities for young people in our region, so the incredible amount of talent we have here, stays here. Our voices must be seen, heard and embraced.</p>

      <p>I’m proud to be supported by the Centre- Left Grassroots Alliance and urge you to support John Bowden, Fianna Hornby and Evangeline Walker.</p>
    </div>,
  },
  {
    position: "SCOTLAND",
    slug: "finn-beyts",
    handle: null,
    name: "Finn Beyts",
    imgUrl: "/img/finn-beyts.png",
    labourMembershipNumber: "L1239090",
    blurb: "I am standing for the National Policy Forum to give Scottish members a clear voice in shaping our policies ahead of the General Election and, hopefully, for the next Labour government. I have been a Labour Party activist for a decade, sat on my CLP executive, and was the co-lead of Glasgow Labour for a Green New Deal. I coordinated the Labour presence for the COP26 climate march and led my university...",
    body: <div>
      <p>I am standing for the National Policy Forum to give Scottish members a clear voice in shaping our policies ahead of the General Election and, hopefully, for the next Labour government.</p>

      <p>I have been a Labour Party activist for a decade, sat on my CLP executive, and was the co-lead of Glasgow Labour for a Green New Deal. I coordinated the Labour presence for the COP26 climate march and led my university Labour Club to join UCU members on the picket line.</p>

      <p>In addition, I have served for four years on the Scottish Co-op Party’s executive as a representative for young members in our sister party, as well as the Scottish representative on the Youth Network’s executive.</p>

      <p>Working in the public sector, I have seen workers undervalued and underpaid – with vital work subcontracted out and our public services being used for private profit. It’s these lived experiences that have made me a committed socialist.</p>

      <p>The NPF has a pivotal role in shaping the policies of the next Labour Government. I want to use my voice to help bring about the change our country needs and fight for better public services, so we can deliver real change that can help everyone.</p>
    </div>
  },
  {
    position: "SCOTLAND",
    slug: "anna-dyer",
    handle: null,
    name: "Anna Dyer",
    imgUrl: "/img/anna-dyer.png",
    labourMembershipNumber: "L0081865",
    blurb: "Labour winning the next General Election will face a 14 year Tory legacy of a divided government presiding over vast economic decline with rampant profiteering, Covid mismanagement, get rich quick schemes for the wealthy leaving an impoverished majority, a disintegrating NHS, and near bankrupt local government . The SNP Government is no better. Tory-lite policies will not fix this nor keep Labour...",
    body: <div>
      <p>Labour winning the next General Election will face a 14 year Tory legacy of a divided government presiding over vast economic decline with rampant profiteering, Covid mismanagement, get rich quick schemes for the wealthy leaving an impoverished majority, a disintegrating NHS, and near bankrupt local government . The SNP Government is no better. Tory-lite policies will not fix this nor keep Labour in power. Labour needs radical and socialist policies to deal with the cost of living crisis, stop NHS privatisation and properly fund public services.</p>

      <p>The NPF must stand against austerity . Plans for future government are appearing without mention of the NPF or community involvement -big business and SMEs are included. To stay in power we need true inclusive party democracy. Labour has reduced the Annual Women’s Conference to an afterthought and eliminated the Disability and BME conferences.</p>

      <hr />

      <p>Relevant Experience.</p>

      <ul>
        <li>NPF member 2022-4</li>
        <li>NCC Chair 1918-1921</li>
        <li>Former Holyrood List candidate</li>
        <li>Recently elected Secretary, Glasgow Labour Women’s Forum.</li>
        <li>Chair of major Development Trust with over 60 all permanent staff in Scotland’s most deprived area. Voluntary Sector Network Elected Representative on Glasgow City Council Community Planning Partnership.</li>
        <li>Project manager for the EU in the transitional economies of Eastern Europe.</li>
      </ul>
    </div>
  },
  {
    position: "SOUTH EAST",
    slug: "charlie-wilson",
    handle: null,
    name: "Charlie Wilson",
    imgUrl: "/img/charlie-wilson.png",
    labourMembershipNumber: "L0080568",
    blurb: "My journey within the party, where I’ve served as a councillor and now CLP chair, has been characterised by a deep-seated desire for social justice, the pursuit of equality and an unwavering commitment to socialist principles. We stand on the brink of government, and my focus will be ensuring our party’s policies both reflect our core values of fairness, solidarity, and community and...",
    body: <div>
      <p>My journey within the party, where I’ve served as a councillor and now CLP chair, has been characterised by a deep-seated desire for social justice, the pursuit of equality and an unwavering commitment to socialist principles.</p>

      <p>We stand on the brink of government, and my focus will be ensuring our party’s policies both reflect our core values of fairness, solidarity, and community and also address the challenges of our time.</p>

      <p>Climate change, economic inequality, and the preservation of public services require bold and visionary policies that can transform our society into one that is more sustainable, equitable, and inclusive. The NPF plays a pivotal role in shaping these policies, and I’m committed to bringing a fresh, progressive perspective to its deliberations.</p>

      <p>By amplifying the voices of our grassroots, engaging with all stakeholders, and harnessing the rich diversity of our party, I believe we can craft a policy agenda that is both radical and achievable. Together, we can build a future that transforms our values into a lived reality for all.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA), please also nominate and vote for Theresa Mackey, Kiran Khan, and Alexa Collins.</p>
    </div>,
  },
  {
    position: "SOUTH EAST",
    slug: "kiran-khan",
    handle: null,
    name: "Kiran Khan",
    imgUrl: "/img/kiran-khan.png",
    labourMembershipNumber: "L1644090",
    blurb: "I bring a deep understanding of the challenges facing working families. Coming from a working-class background myself, I understand intimately the struggles and aspirations of ordinary people. My personal experiences have shaped my commitment to fighting for policies that prioritise social justice, economic redistribution, and equal opportunities for all. I have a proven track record of grassroots...",
    body: <div>
      <p>I bring a deep understanding of the challenges facing working families. Coming from a working-class background myself, I understand intimately the struggles and aspirations of ordinary people. My personal experiences have shaped my commitment to fighting for policies that prioritise social justice, economic redistribution, and equal opportunities for all.</p>

      <p>I have a proven track record of grassroots activism and community organising. I’ve been actively involved in numerous campaigns advocating for progressive causes. I’ve been a Labour Member since 2017 and a Councillor since 2021 and during this time, I have advocated for transformative policies that reflect Labour values.</p>

      <p>The Next Labour government needs to take bold action on many issues facing the country such as housing, austerity, NHS and lack of funding for public services and local councils. I’ll fight for Labour to adopt policies to tackle these challenges we face.</p>

      <p>As a member of Labour’s National Policy Forum, I will work tirelessly to ensure that the voices of ordinary members are heard and reflected in the party’s policy agenda.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA), please also nominate and vote for Theresa Mackey, Charlie Wilson, and Alexa Collins.</p>
    </div>,
  },
  {
    position: "SOUTH EAST",
    slug: "alexa-collins",
    handle: null,
    name: "Alexa Collins",
    imgUrl: "/img/alexa-collins.png",
    labourMembershipNumber: "L1281294",
    blurb: "Working in local government, I am well aware of the choices councils need to make in policy development and service delivery during tough economic circumstances. As the prospect of a Labour Government becomes reality, we have the opportunity for our policies to dramatically improve the lives of everyone in this country; policies desperately needed by so many. With a background in central and...",
    body: <div>
      <p>Working in local government, I am well aware of the choices councils need to make in policy development and service delivery during tough economic circumstances. As the prospect of a Labour Government becomes reality, we have the opportunity for our policies to dramatically improve the lives of everyone in this country; policies desperately needed by so many.</p>

      <p>With a background in central and local government finance, I have a strong, relevant skill set to contribute to effective policy development for the Labour Party and I am driven to contribute wherever I can to make positive change.</p>

      <p>As well as being a Labour activist, I have been a CLP chair, Secretary, Treasurer and I Chaired the Local Campaign Forum for several years, making it possible for every ward in my council area to field Labour candidates for the first time ever and give every voter the opportunity to vote Labour.  I campaign for fair school admissions which is particularly relevant in much of the South East region. I am also a parish councillor.</p>

      <p>I’m proudly endorsed by the Centre-Left Grassroots Alliance and Momentum.</p>

      <p>Please also nominate and vote for: Kiran Khan, Therese Mackey, and Charlie Wilson.</p>
    </div>,
  },
  {
    position: "SOUTH EAST",
    slug: "theresa-mackey",
    handle: null,
    name: "Theresa Mackey",
    imgUrl: "/img/theresa-mackey.png",
    labourMembershipNumber: "L0058638",
    blurb: null,
    body: null,
  },
  {
    position: "SOUTH WEST",
    slug: "marina-asvachin",
    handle: null,
    name: "Marina Asvachin",
    imgUrl: "/img/marina-asvachin.png",
    labourMembershipNumber: "L1279958",
    blurb: "I’ve been a Devon County Councillor for 7 years & a City Councillor for my home City of Exeter for 2. I currently chair the Labour group on the former & am chair of the licensing committee on the latter. I also chaired our local branch for some years. I became a Councillor because I wanted to be able to help people, particularly in my own community which is extremely diverse but also with significant...",
    body: <div>
      <p>I’ve been a Devon County Councillor for 7 years & a City Councillor for my home City of Exeter for 2. I currently chair the Labour group on the former & am chair of the licensing committee on the latter. I also chaired our local branch for some years. I became a Councillor because I wanted to be able to help people, particularly in my own community which is extremely diverse but also with significant pockets of deprivation.</p>

      <p>I’m currently the organiser for our ward for this year’s local elections. I worked for the NHS as a Clinical Technologist for over 20 years where I was a Unite Rep. I now belong to the Unite Community Branch & am trained as a Companion Rep. I am also a governor at my local nursery school and chair of trustees at our Community Centre.</p>

      <p>I pledge to always fight for equal opportunities for all, democracy, the environment, and the best education & healthcare we deserve. We must also ensure the New Deal for Working people is delivered fully.</p>

      <p>I am extremely proud to have the support of the Centre-left Grassroots alliance and Momentum. I urge you to also support Jane Begley and Ada Gravatt (Youth position).</p>
    </div>,
  },
  {
    position: "SOUTH WEST",
    slug: "jane-begley",
    handle: null,
    name: "Jane Begley",
    imgUrl: "/img/jane-begley.png",
    labourMembershipNumber: "L1441041",
    blurb: "My professional life has been spent working with children and young people in various mental health settings. Whilst raising my daughter, single-handedly, I trained & worked as a child psychotherapist in NHS CAMHS. Currently, I work with kids in care, who’ve survived severe neglect & abuse. I frequently draw upon the emotional intelligence that my mental health training has fine-tuned, as a city councillor...",
    body: <div>
      <p>My professional life has been spent working with children and young people in various mental health settings. Whilst raising my daughter, single-handedly, I trained & worked as a child psychotherapist in NHS CAMHS. Currently, I work with kids in care, who’ve survived severe neglect & abuse.</p>

      <p>I frequently draw upon the emotional intelligence that my mental health training has fine-tuned, as a city councillor in Exeter, representing a ward with swathes of social housing, deprivation, and residents with complex needs, and as a trustee of the local community centre.</p>

      <p>In my CLP, I’ve been Women’s Officer, LGBT Officer, Deputy Branch Chair, and GC Branch Representative. I’ve also been delegate to Annual Conference and Women’s Conference.</p>

      <p>Working in the NHS, I witnessed the devastating effects of austerity, with increasing demands and diminishing resources, to deliver frontline services. I remain fully committed to fighting for publicly funded health and social care services. Incompetence and corruption of successive Tory governments, have made the path ahead challenging. However, I’ll always strive to support the disadvantaged and disenfranchised.</p>

      <p>I am extremely proud to have the support of the Centre-left Grassroots alliance and Momentum. I urge you to also support Marina Asvachin and Ada Gravatt (Youth position).</p>
    </div>,
  },
  {
    position: "SOUTH WEST (YOUTH REP)",
    slug: "ada-gravatt",
    handle: null,
    name: "Ada Gravatt",
    imgUrl: "/img/ada-gravatt.png",
    labourMembershipNumber: "L194713",
    blurb: "I’m Ada Gravatt, an active member of the Bristol Central CLP and the University of Bristol Labour Society. With the tangible prospect of a Labour Government with a sizable majority, it is incumbent upon us to ensure we grasp that opportunity and outline a progressive policy platform which can transform Britain and young people’s lives. The NPF has a pivotal role to play in advocating for the policies we need...",
    body: <div>
      <p>I’m Ada Gravatt, an active member of the Bristol Central CLP and the University of Bristol Labour Society.</p>

      <p>With the tangible prospect of a Labour Government with a sizable majority, it is incumbent upon us to ensure we grasp that opportunity and outline a progressive policy platform which can transform Britain and young people’s lives. The NPF has a pivotal role to play in advocating for the policies we need to bring about a fairer, greener future.</p>

      <p>Within the NPF, I pledge to champion Labour’s New Deal for Working People in full, a comprehensive framework designed to secure enhanced rights for workers spanning various sectors of the economy. Moreover, I am committed to advocating for robust climate policies, fostering inclusivity within the party, particularly towards trans people, and vehemently opposing regressive migration policies such as the Conservative’s abhorrent Rwanda plan. I will also continue to advocate strongly for the abolition of tuition fees and an improved housing settlement, making sure our education system is accessible to all.</p>

      <p>Securing your support would be an immense privilege. I am proud to have the backing of the Centre-left Grassroots alliance (CLGA) and ask you to also support Marina Asvachin and Jane Begley.</p>
    </div>,
  },
  {
    position: "WALES",
    slug: "zoe-allan",
    handle: null,
    name: "Zoe Allan",
    imgUrl: "/img/zoe-allan.png",
    labourMembershipNumber: "L1393044",
    blurb: "Having been elected to the NPF last time, I can report that at the full NPF meeting us reps are told that the first rule of the full NPF is, “You must NOT talk about the full NPF!”. Within the bounds of confidentiality then, I will say that myself and colleagues championed the socialist successes and the interests of Wales at every opportunity. Universal Free School Meals for primary school...",
    body: <div>
      <p>Having been elected to the NPF last time, I can report that at the full NPF meeting us reps are told that the first rule of the full NPF is, “You must NOT talk about the full NPF!”. Within the bounds of confidentiality then, I will say that myself and colleagues championed the socialist successes and the interests of Wales at every opportunity.</p>

      <p>Universal Free School Meals for primary school children, scrapping Right To Buy to stop the depletion of council house stock and never having allowed the academisation of schools were just three of the bold Welsh Labour policies we raised as having helped our electoral success.</p>

      <p>The need for a fair deal for Wales and an internationalist approach which promotes peace were also flagged up. Working with like-minded reps from across Britain, we made a positive impact and would be honoured to do the same again.</p>

      <p>With the likelihood of a Labour government in Westminster getting closer by the day, it’s more important than ever to push for radical, yet common sense policies which both the membership and electorate want to see in action. Please also support Bethany Thomas (Youth), David Smith, Bel Loveluck-Edwards and Dawn McGuinness.</p>
    </div>,
  },
  {
    position: "WALES",
    slug: "bel-loveluck-edwards",
    handle: null,
    name: "Bel Loveluck Edwards",
    imgUrl: "/img/bel-loveluck-edwards.png",
    labourMembershipNumber: "L0123450",
    blurb: null,
    body: null,
  },
  {
    position: "WALES",
    slug: "dawn-mcguinness",
    handle: null,
    name: "Dawn McGuinness",
    imgUrl: "/img/dawn-mcguinness.png",
    labourMembershipNumber: "L1681583",
    blurb: <span>
      I am a committed socialist and lifelong Labour voter. I was inspired to join the party in 2017 by the message of hope for a better future for the many, that shone through in...
      <hr classname="m-2" style={{margin: "20px 40px"}} />
      Rwy’n sosialydd ymroddedig ac yn bleidleisiwr Llafur gydol oes. Cefais fy ysbrydoli i ymuno â’r blaid yn 2017 gan y neges o obaith am ddyfodol gwell, a oedd yn disgleirio drwodd yn...
    </span>,
    body: <div class="row p-0">
      <div class="col-sm no-gutters">
        <p>I am a committed socialist and lifelong Labour voter. I was inspired to join the party in 2017 by the message of hope for a better future for the many, that shone through in that manifesto. I am a member of Unite the Union. I have been Treasurer for Bangor Aberconwy CLP since 2019 and have represented our CLP as their delegate at both UK and Welsh Conference. I was proud to be selected by our CLP as their candidate for the 2021 Senedd Election. I have also been a member of the Standing Orders Committee since 2019.</p>

        <p>I was elected to the NPF in 2022 and worked hard during the NPF Weekend in Nottingham to ensure that our next manifesto is equally inspiring to the electorate at the next General Election. We need to ensure that it includes policies which will protect the most vulnerable in our society from the hardships that they currently face due to the cost of living crisis, and invest in and rebuild our public services following years of decimation under Tory austerity. I would appreciate the opportunity to continue this work.</p>

        <p>Please also support Bethany Thomas (Youth), David Smith, Zoe Allan and Bel Loveluck-Edwards.</p>
      </div>
      <div class="col-sm">
        <p>Rwy’n sosialydd ymroddedig ac yn bleidleisiwr Llafur gydol oes. Cefais fy ysbrydoli i ymuno â’r blaid yn 2017 gan y neges o obaith am ddyfodol gwell, a oedd yn disgleirio drwodd yn y maniffesto hwnnw. Rwy’n aelod o’r undeb Unite. Rwyf wedi bod yn Drysorydd ar gyfer CLP Bangor Aberconwy ers 2019 ac wedi bod cynrychiolydd yng Nghynhadledd y DU a Chymru. Roeddwn yn falch o gael fy newis fel hymgeisydd ar gyfer Etholiad y Senedd 2021. Rwyf hefyd wedi bod yn aelod o’r SOC ers 2019.</p>

        <p>Cefais fy ethol i’r NPF yn 2022 a gweithiais yn galed yn ystod Penwythnos yr NPF yn Nottingham i sicrhau bod ein maniffesto nesaf yr un mor ysbrydoledig i’r etholwyr yn yr Etholiad Cyffredinol nesaf. Mae angen inni sicrhau ei fod yn cynnwys polisïau a fydd yn amddiffyn y rhai mwyaf agored i niwed yn ein cymdeithas rhag y caledi y maent yn ei wynebu ar hyn o bryd oherwydd yr argyfwng costau byw, a buddsoddi yn ein gwasanaethau cyhoeddus a’u hailadeiladu, yn dilyn blynyddoedd o ddinistr o dan lymder y Torïaid. Byddwn yn gwerthfawrogi’r cyfle i barhau â’r gwaith hwn.</p>

        <p>Cefnogwch hefyd Bethany Thomas (Ieuenctid), David Smith, Zoe Allan a Bel Loveluck-Edwards.</p>
      </div>
    </div>
  },
  {
    position: "WALES",
    slug: "david-smith",
    handle: null,
    name: "David Smith",
    imgUrl: "/img/david-smith.png",
    labourMembershipNumber: "A406305",
    blurb: <span>
      A clear, robust, and experienced voice is needed to represent Welsh members on this UK-wide Labour policy forum. It also requires a good understanding of Welsh policy making...
      <hr classname="m-2" style={{margin: "20px 40px"}} />
      Mae angen llais clir, cadarn, a phrofiadol i gynrychioli aelodau Cymru ar y fforwm polisi Llafur y DU. Mae hefyd yn gofyn am ddealltwriaeth dda o lunio polisïau Cymru, y gallu i ddeall...
    </span>,
    body: <div class="row p-0">
      <div class="col-sm no-gutters">
        <p>A clear, robust, and experienced voice is needed to represent Welsh members on this UK-wide Labour policy forum. It also requires a good understanding of Welsh policy making, the ability to understand and reflect upon the diversity of policies across the four UK countries, and an ability to get the best outcome for Wales.</p>

        <p>As a long-standing Party member, I played a lead role within my previous CLP, (Newport West), in drafting responses to each of the six NPF policy themes and subsequent amendments. If elected, I would look to have a radical and positive influence on UK-wide Labour Party policy and strengthen our future Senedd manifesto particularly to securing better, adequately funded, Welsh public services.</p>

        <p>My experience as a Welsh citizen’s voice within the UK Food Standards Agency, the UK Co-operative Group’s Main Board, and the UK Healthcare professional standards body, also provides the breadth of experience needed to achieve change.</p>

        <p>I would appreciate your vote and, if elected, promise to represent you by listening, encouraging, promoting, and supporting your voice on the NPF. I will report back on the progress made on your behalf. Please also support Bethany Thomas (Youth), Bel Loveluck-Edwards, Zoe Allan, and Dawn McGuinness.</p>
      </div>
      <div class="col-sm">
        <p>Mae angen llais clir, cadarn, a phrofiadol i gynrychioli aelodau Cymru ar y fforwm polisi Llafur y DU. Mae hefyd yn gofyn am ddealltwriaeth dda o lunio polisïau Cymru, y gallu i ddeall a myfyrio ar yr amrywiaeth o bolisïau dros y pedair cenedl, ac i gael y canlyniad gorau i Gymru.</p>

        <p>Fel aelod hirsefydlog o’r Blaid, chwaraeais rhan arweiniol yn fy CLP blaenorol, (Gorllewin Casnewydd), wrth ddrafftio ymatebion i bob un o’r chwech themâu polisi’r NPF a diwygiadau dilynol. Os caf fy ethol, byddwn yn edrych i gael dylanwad radical a chadarnhaol ar bolisi’r Blaid Lafur ledled y DU, a chryfhau maniffesto y Senedd yn y dyfodol, yn enwedig i sicrhau gwell gwasanaethau cyhoeddus Cymraeg, sydd wedi’u hariannu’n ddigonol.</p>

        <p>Mae fy mhrofiad fel llais dinesydd Cymraeg o fewn Asiantaeth Safonau Bwyd y DU, Prif Fwrdd Grŵp Cydweithredol y DU, a chorff safonau proffesiynol Gofal Iechyd y DU, hefyd yn darparu’r ehangder o brofiad sydd ei angen i gyflawni newid.</p>

        <p>Byddwn yn gwerthfawrogi eich pleidlais ac, os caf fy ethol, byddwn yn addo eich cynrychioli drwy wrando, calonogi, hyrwyddo a chefnogi eich llais ar yr NPF. Byddaf yn adrodd yn ôl ar y cynnydd wedi ei wneud ar eich rhan.</p>
      </div>
    </div>
  },
  {
    position: "WALES (YOUTH REP)",
    slug: "bethany-thomas",
    handle: null,
    name: "Bethany Thomas",
    imgUrl: "/img/bethany-thomas.png",
    labourMembershipNumber: "L1796866",
    blurb: "Coming from a working-class family, I have been a dedicated carer for my disabled mother, navigating the challenges that come with it. As a gay, jewish and neurodivergent person, I am passionate about advocating for inclusivity and understanding within the Labour Party. As the Vice President of Cardiff Labour Students and Disability Officer for Cardiff Young Labour, I actively contribute...",
    body: <div>
      <p>Coming from a working-class family, I have been a dedicated carer for my disabled mother, navigating the challenges that come with it. As a gay, jewish and neurodivergent person, I am passionate about advocating for inclusivity and understanding within the Labour Party.</p>

      <p>As the Vice President of Cardiff Labour Students and Disability Officer for Cardiff Young Labour, I actively contribute to fostering a diverse and representative political environment. My involvement extends to drafting motions for my university’s AGM, showcasing my ability to create and scrutinise policies. Additionally, I hold the position of a student senator, further emphasising my commitment to effective governance.</p>

      <p>If elected, I am determined to contribute meaningfully to the NPF and push Wales’ interests forward. Drawing from my personal experiences, I will be a critical friend, ensuring that policies address the needs of those often overlooked. I am dedicated to championing policies like affordable housing, a new deal for working people, accessible higher education for all, and a strong, publicly funded NHS.</p>

      <p>I urge you to nominate and vote for David Smith, Dawn McGuinness, Bel Loveluck-Edwards, and Zoe Allan for NPF, who also have the backing of Momentum and Welsh Labour Grassroots.,</p>
    </div>
  },
  {
    position: "WEST MIDLANDS",
    slug: "teresa-beddis",
    handle: null,
    name: "Teresa Beddis",
    imgUrl: "/img/teresa-beddis.png",
    labourMembershipNumber: "A399419",
    blurb: "I have been on the NPF since 2018, serving on the economy commission. I have supported several CLPs – attending meetings and leading policy discussions. I attended almost every commission meeting (London and Zoom) including the full NPF last July. I am a retired A Level and advisory teacher. My special interests include the economy, education, health, the environment and animal welfare. I have approximately...",
    body: <div>
      <p>I have been on the NPF since 2018, serving on the economy commission. I have supported several CLPs – attending meetings and leading policy discussions. I attended almost every commission meeting (London and Zoom) including the full NPF last July.</p>

      <p>I am a retired A Level and advisory teacher. My special interests include the economy, education, health, the environment and animal welfare. I have approximately 25 years experience holding virtually every Branch and CLP position, Conference delegate and an election candidate at local and national level.</p>

      <p>I have been a socialist all my life – committed to the many, not the few. I have used my time on the NPF to promote these values and if re-elected will continue to do so.</p>

      <p>Our Party has unfortunately got it totally wrong on Gaza – refusing to call for an immediate ceasefire and the cessation of arms sales despite overwhelming public support as well as lacklustre condemnation of war crimes. We must use bodies like the NPF to restore trust in our communities and develop progressive policies at home and abroad.</p>

      <p>Please support me plus the following CLGA candidate – Niamh Illif (Youth representative). Thank you.</p>
    </div>,
  },
  {
    position: "WEST MIDLANDS (YOUTH REP)",
    slug: "niamh-iliff",
    handle: null,
    name: "Niamh Iliff",
    imgUrl: "/img/niamh-iliff.png",
    labourMembershipNumber: "L3102095",
    blurb: "Grounded in grassroots organising with my local TUC, left-wing student groups, youth organisations, local campaigns, and my union branch, my priorities lie in amplifying the voices of working people and communities. Only through representing such groups at the base of the party can we ensure Labour’s policies remain dynamic and transformative. Serving as Trade Union Liaison Officer for West Midlands...",
    body: <div>
      <p>Grounded in grassroots organising with my local TUC, left-wing student groups, youth organisations, local campaigns, and my union branch, my priorities lie in amplifying the voices of working people and communities. Only through representing such groups at the base of the party can we ensure Labour’s policies remain dynamic and transformative.</p>

      <p>Serving as Trade Union Liaison Officer for West Midlands Young Labour, I’ve advocated for crucial issues across the region and created meaningful links between the party and unions, amplifying grassroots concerns. By giving a platform to and hearing young members, I’m dedicated to representing the future of our party as vital voices in policy making.</p>

      <p>We must create a radical policy agenda that unites unionists, members, and activists which will uphold our progressive values and help shape a brighter, more equitable future together. Fighting for the delivery of a Green New Deal that unites these groups within our party and truly supports our communities in the future will be core to my addition to the NPF.</p>

      <p>I am proud to be supported by the Centre-Left Grassroots Alliance and Momentum. I urge you to vote for Teresa Beddis.</p>
    </div>,
  },
  {
    position: "YORKSHIRE AND THE HUMBER",
    slug: "chris-saltmarsh",
    handle: null,
    name: "Chris Saltmarsh",
    imgUrl: "/img/chris-saltmarsh.png",
    labourMembershipNumber: "L1180242",
    blurb: "I am standing as a candidate for the National Policy Forum in Yorkshire and The Humber to promote the transformative policy agenda that Labour needs to tackle the great crises of the twenty-first century when in government: climate, cost-of-living, health, international conflict, and beyond. As a co-founder of Labour for a Green New Deal, I have experience of working with stakeholders throughout the party...",
    body: <div>
      <p>I am standing as a candidate for the National Policy Forum in Yorkshire and The Humber to promote the transformative policy agenda that Labour needs to tackle the great crises of the twenty-first century when in government: climate, cost-of-living, health, international conflict, and beyond.</p>

      <p>As a co-founder of Labour for a Green New Deal, I have experience of working with stakeholders throughout the party – members, trade unions, MPs, shadow ministers – to develop ambitious and popular policies to rapidly decarbonise, create good green jobs, invest in reindustrialisation, expand public ownership, re-empower trade unions, and protect public services.</p>

      <p>Living in Sheffield, with its proud history of steel manufacturing, I believe that Yorkshire and The Humber can be at the heart of Labour’s efforts to tackle climate change while revitalising the UK’s industrial economy.</p>

      <p>The upcoming Labour government is a historic opportunity and I will work within the NPF to ensure that we make the most of it with a socialist policy platform worthy of the uncertain times we are living through.</p>

      <p>I am proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA). Please also nominate Jack Ballingham, Sandra Wyman, and Corinne Furness.</p>
    </div>,
  },
  {
    position: "YORKSHIRE AND THE HUMBER",
    slug: "jack-ballingham",
    handle: null,
    name: "Jack Ballingham",
    imgUrl: "/img/jack-ballingham.png",
    labourMembershipNumber: "L1766555",
    blurb: "I’ve represented Yorkshire and Humber members on the National Policy Forum since 2022, and am now seeking your support to continue ensuring our region’s members are heard in our Party’s policy-making spaces. I currently serve as treasurer of Selby CLP, and as a delegate to Leeds Local Government Committee. I want to help ensure that our Party has the platform it needs to win the next general election, and...",
    body: <div>
      <p>I’ve represented Yorkshire and Humber members on the National Policy Forum since 2022, and am now seeking your support to continue ensuring our region’s members are heard in our Party’s policy-making spaces.</p>

      <p>I currently serve as treasurer of Selby CLP, and as a delegate to Leeds Local Government Committee. I want to help ensure that our Party has the platform it needs to win the next general election, and a strong plan for government. As a young person, I know the importance of bold, progressive policies in areas like education and housing, and will work to promote these at the NPF.</p>

      <p>Over the last two years I’ve served as a member of the NPF’s foreign and defence policy commission, and attended the full meeting of the NPF at Nottingham last year. There I focused on a range of policy issues, such as the arms trade, voter ID and protest laws, asylum and further education, and was able to secure substantial policy changes in these areas.</p>

      <p>I am proud to be backed by Momentum and the Centre-Left Grassroots Alliance (CLGA). Please also nominate and vote for Chris Saltmarsh, Sandra Wyman, and Corinne Furness.</p>
    </div>,
  },
  {
    position: "YORKSHIRE AND THE HUMBER",
    slug: "corinne-furness",
    handle: null,
    name: "Corinne Furness",
    imgUrl: "/img/corinne-furness.png",
    labourMembershipNumber: "L1493177",
    blurb: "Since accidentally becoming a branch rep at my first ever Labour party meeting, I’ve held roles at CLP level for most of the last eight years – most recently as Chair of Leeds East CLP – and have represented members on the Leeds LGC. For more than a decade I worked in community arts, contributing policy research on everything from community-led high street regeneration to working class access to the arts and...",
    body: <div>
      <p>Since accidentally becoming a branch rep at my first ever Labour party meeting, I’ve held roles at CLP level for most of the last eight years – most recently as Chair of Leeds East CLP – and have represented members on the Leeds LGC. For more than a decade I worked in community arts, contributing policy research on everything from community-led high street regeneration to working class access to the arts and government-led arts policy.</p>

      <p>From the council house I grew up in to the Education Maintenance Allowance I received in sixth form, I’ve had opportunities which have been enabled by Labour being bold in government. We can see the impact of the climate emergency, the housing crisis, the hostile environment, and austerity on our communities in Yorkshire. Only bold, progressive policies have the answers to the crises we face and we need NPF reps who will champion these policies.</p>

      <p>I commit to representing the views of grassroots members, acting with transparency, and fighting for the policies which will help transform our communities.</p>

      <p>I’m proud to be backed by Momentum and the Centre-Left Grassroots Alliance (CLGA). Please also nominate and vote for Chris Saltmarsh, Sandra Wyman, and Jack Ballingham.</p>
    </div>,
  },
  {
    position: "YORKSHIRE AND THE HUMBER",
    slug: "sandra-wyman",
    handle: null,
    name: "Sandra Wyman",
    imgUrl: "/img/sandra-wyman.png",
    labourMembershipNumber: "L1640558",
    blurb: "As an older disabled woman living in inner city Leeds, I’m well aware of the difficulties faced by those affected by austerity.  I first joined the Labour Party in the 1980s; currently I’m the CLP Disability Officer and am a member of the national Disability Labour executive. I’ve also served as a member of Leeds LGC. Pre-retirement I was Leeds NUT (now NEU) President and regional representative on a...",
    body: <div>
      <p>As an older disabled woman living in inner city Leeds, I’m well aware of the difficulties faced by those affected by austerity.  I first joined the Labour Party in the 1980s; currently I’m the CLP Disability Officer and am a member of the national Disability Labour executive. I’ve also served as a member of Leeds LGC.</p>

      <p>Pre-retirement I was Leeds NUT (now NEU) President and regional representative on a national equalities committee. I was also an LEA Equalities Officer.  Recently I’ve worked on Unite Community campaigns against Ticket Office closures, Fuel Poverty and the Cost of Living crisis. </p>

      <p>Living near one of Leeds’ poorest areas, I’m familiar with the damage to people’s lives caused by Tory austerity.  Our priority is to elect a Labour government; equally we need transformative policies to support those affected by rogue landlords and vastly increased food and fuel prices so people can be healthier and happier.</p>

      <p>I’m proud to be supported by Momentum and the Centre-Left Grassroots Alliance (CLGA).  I support party democracy, will represent grassroots members and will report back.</p>

      <p>Please also nominate Corinne Furness, Jack Ballingham, and Chris Saltmarsh.</p>
    </div>,
  }
]

export default Candidates
