import React from 'react';
import HeaderTags from '../../components/HeaderTags';
import Template from '../../components/Template/Template';
import { Container, Jumbotron, Row, Col, Navbar, NavbarBrand} from 'reactstrap';
import { Link } from 'react-router-dom';
import Form from '../../components/Form';
import CandidateLanding from '../../components/CandidateLanding/CandidateLanding';
import ReactMarkdown from 'react-markdown';
import Accordion from '../../components/Collapse/Collapse'
import C2ASection from '../../components/C2ASection'
import './Landing.scss'

const copy_statement = `

## Momentum and the CLGA are supporting candidates for Labour's National Executive Committee and the National Policy Forum who will fight for real Labour values and oppose anti-democratic actions by the Party leadership.

After 14 years of austerity, privatisation and inequality - The Tories have been booted out. Labour now has a once-in-a-generation opportunity to reshape Britain's economy in favour of the many, rather than the interests of an established few.

But despite some advances, Keir Starmer has avoided making clear commitments on fiscal policy, leading commentators to point out the risk of 'baked-in austerity', which would have a catastrophic impact on society. The Islamophobic and racist violence of the far right we have seen recently shows that a failure to bring in bold,transformative policies will only embolden the far right.

Furthermore, the Labour Leadership shows no signs of dialling down its war on pluralism and dissenting voices within the Party. The decision to withdraw the whip from 7 MPs for voting to scrap the 2-Child Benefit Cap - a policy supported by the labour movement - demonstrated appalling authoritarianism.

That's why we are supporting candidates with track records of standing up for democracy, opposing anti-democratic rule changes, and defending the rights of members in selections and policy. Under a Labour Government, it's vital we have representatives who support these demands now more than ever.

*Ballots are now open. To find your ballot, search for the subject line **'Your ballot - Labour Party elections'** in your inbox. Vote for the #GV4 to the NEC in the CLP section, the Centre Left Grassroots Alliance-backed candidates for the NPF and other Grassroots Voice candidates where you are eligible. Voting closes on Tuesday 17th September. Type your postcode into [our preferencing tool](https://futureweneed.com/preference/) to find out how to rank the #GV4 candidates standing in the CLP section. Let's do this!*
`

const values_copy = `
# What we stand for:

**For an economy for the many, not the few**

Our rigged system is in crisis: spiralling energy bills and a cost of living crisis, while billionaire profits soar. The alternative is socialist and it's popular. A higher minimum wage, public ownership, strengthened trade unions, more and better housing for the working class. This is the future we need.

**For a Green New Deal**

It’s code red for the climate. We need to take on the fossil fuel industry and transform our economy to be greener, cleaner and fairer. Together, we can build a zero-carbon economy in the interests of the millions, not the millionaires. 


**For liberation and equality**

In the past two years we have witnessed protests against institutional sexism and racism in the police, while the Tory Government imposes racist new laws to strengthen police powers, attack people seeking asylum and strip our citizenship rights. We will take on the political elites fostering division and domination. And we will fight for a party unifed in the fights against racism, antisemitism, Islamophobia, Afrophobia, homophobia, transphobia, biphobia, ableism, sexism, sexual harassment, and the scapegoating of migrant and Traveller communities.


**For a democratic party**

Labour is a democratic socialist party and should be run as such. Our members come from all walks of life - they are the backbone of our Party. Members should guide policy through Conference, our sovereign body, and choose their own candidates for elections, not have them imposed from on high. Our Party’s autonomous structures should be respected.

The time for waiting is over. Let’s build a Labour Party that will fight for the future we need.
`

const preferenceTool_standfirst_copy=`
# How to Vote
`

const preferenceTool_copy = `

Elections for the NEC CLP section will take place by Single Transferable Vote. Find out how this works and how to maximise the chances of getting Grassroots Voice candidates elected.
`

const statementOfPrinciples_copy = `
# Our values

Grassroots Voice stands for intersectionality and working class unity, and for dismantling structural inequalities and confronting all forms of prejudice both within the Labour Party and wider society. 
`

const whatIsTheNEC_standfirst_copy =`## What is the NEC?`

const whatIsTheNEC_body_copy =`
Labour's National Executive Committee (NEC) is the party's governing body, which oversees the way our Party is run. 

- It plays a crucial role in the process for deciding Labour’s manifesto. 

- It makes key decisions determining who can 
become a Labour parliamentary candidate and who can be elected in other internal Party elections. 

- It makes vital decisions about Party Conference including making recommendations for or against important rule changes. 

- It appoints the Party's general secretary and recently appointed the panellists for the inquiry into the leaked Labour report. 

- It is responsible for implementing the Party’s rule book and for managing the disciplinary processes. 

In short, the NEC makes big decisions on behalf of the party. Therefore, who gets to sit on it and what their political beliefs and motivations are is extremely important.

### Why are these NEC elections so important?

These elections are a huge opportunity for the left to build power in the party. We can push the leadership to adopt a Green New Deal 2030, support Black Lives Matter, and commit to a transformative post-pandemic policy programme, policies which are overwhelmingly popular with voters. But to do that we need more socialists on the NEC. 

We can improve Party transparency, drive forward member-led democracy, ensure justice for the abuse and misconduct exposed by the ‘Labour Leaks’ and prevent any return to purges of membership and attempts to stitch up votes and break the Party’s rules. 

We have the numbers to win this election. If we work together, we can win. 
`

const electionProcess_copy = `

## Nominate these candidates!

CLPs have until 31st July to nominate NEC candidates. Following this there will be a ballot using Single Transferable Voting between 27th August and 17th September.

`
// ### Where is my ballot?

// The Labour Party will email you a ballot on or soon after Monday 19 October - just follow the link in the email to cast your votes. Make sure you cast your vote before the deadline: midday on Thursday 12 November. 

const whereIsMyBallot_body_copy = `
### Which posts can I vote for? 

All members can vote for the Constituency Labour Party (CLP) representative positions. 

Young members (aged under 27) can vote for the youth representative position. 

Members who self-define as an ethnic minority can vote for the BAME representative position.

Members who self-define as disabled can vote for the Disabled members' representative position.

Members in Wales can vote for the Welsh representative position.
`

const keyDates_body_copy = `
### Key dates: 
#### Tuesday 27th August:
Labour begins emailing out ballots. Voting opens.
#### Tuesday 17th September:
Voting closes.
`

const supportTheCampaign_copy=`
# Donate to Grassroots Voice

A Green New Deal, vocal support for Black Lives Matter, a post-pandemic economy for the many: so much is on the line in Labour’s upcoming NEC elections. Contribute to the Grassroots Voice election fund and help fight for the future we need.
`

const shareCardDescription_copy=`
A Labour NEC campaign fighting for democracy & socialism
`

const flagWrapper = (flag, child) => {
  const boolean = flag === "true"
  if (boolean) {
    return child 
  } else {
    return null
  }
}

const CollapseBanner = (props) => {
  return (
    <div className="bg-red color-light collapse-section">
      <Container className="p-0">
        <Row className="no-gutters">
          <Col className="justify-content-center" sm={{ size: 12 }}>
            <Accordion
              closedButtonText="read more"
              openButtonText="read less"
              standfirst={props.standfirst}
              body={props.body}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

// Sort-of copy of the above but not an expanding section and way less overcomplicated,
const HowToVoteBanner = (props) => {
  return (
    <div className="bg-yellow collapse-section">
      <Container className="p-0">
        <Row className="no-gutters">
          <Col className="justify-content-center" sm={{ size: 12 }}>
            <div>
              <div className="d-flex justify-content-center">
                <h2>Make sure you vote efficiently!</h2>
                <a className="btn btn-red btn-outline-secondary type-label-1 d-flex align-items-center outside-btn btn btn-red" href="/preference">
                  See our voting preference tool here
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

function Landing({ history }) {
  return (
    <Template id="landing" removeNav={true}>
      <HeaderTags
        title="Grassroots Voice: The Future We Need"
        url=""
        imgUrl="/img/candidates_2024.png"
        description={shareCardDescription_copy}
        large={true} />
      <div id="landing-bg">
        <Jumbotron fluid className="p-0 mb-0">
          <Container fluid className="hero p-0">
            <Navbar className="transparent py-0">
              <Container className="innerNav p-0">
                <NavbarBrand className="p-0" to="/" tag={Link}>
                  <img
                    src="/img/GV_logo_redback.svg"
                    alt="The future we need"
                  />
                </NavbarBrand>
              </Container>
            </Navbar>
            <Form history={history} />
          </Container>
        </Jumbotron>
      </div>

      <div className="bg-neutral">
        <Container className="py-4 px-0" >
          <Row className="landing_statement no-gutters">
            <Col>
              <ReactMarkdown className="statement" source={copy_statement} />
            </Col>
          </Row>
        </Container>
      </div>

      <CollapseBanner 
        standfirst={<ReactMarkdown source={whatIsTheNEC_standfirst_copy} />}
        body={<ReactMarkdown source={whatIsTheNEC_body_copy} />}
      />

      <HowToVoteBanner />
      
      <CandidateLanding />

      <div id="howtovote" className="c2aSection bg-yellow">
        <Container className="p-4">
          <Row className="p-0 no-gutters">
            <Col sm={{ size: 12 }} md={{ size: 9 }}>
              <ReactMarkdown source={preferenceTool_standfirst_copy} />
              {flagWrapper(process.env.REACT_APP_SHOW_PREFERENCE_TOOL, 
              <>
                <ReactMarkdown source={preferenceTool_copy} />
                <Col className="p-0" sm={{ size: 12 }} md={{ size: 8 }}>
                  <a className="btn btn-block type-label-1 mt-2 btn-outline-secondary" href="/preference">
                    Find your order of preference
                  </a>
                </Col>
              </>
              )}
            </Col>
            {flagWrapper(process.env.REACT_APP_SHOW_PREFERENCE_TOOL, 
              <Col sm={{ size: 12 }} md={{ size: 2, offset: 1 }}>
                <div className="d-grid">
                  <img src="/img/vote_pref_illustration.png" alt=""/>
                </div>
              </Col>
            )}            
            </Row>
          <Row>
            <Col sm={{size: 6}}>
              <ReactMarkdown source={whereIsMyBallot_body_copy} />
            </Col>
            <Col sm={{size: 6}}>
              <ReactMarkdown source={keyDates_body_copy} />
            </Col>
          </Row>
        </Container>
      </div>      

      <div className="about bg-neutral">
        <Container>
          <Row className="landing_statement no-gutters">
            <Col sm={{ size: 12 }} md={{ size: 12 }} lg={{size: 7}} xl={{size: 7}}>
              <ReactMarkdown className="values" source={values_copy} />
            </Col>
          </Row>
        </Container>
      </div>
      
      <C2ASection
        bgClass="bg-red"
        btnClass="btn-outline-primary"
        textClass="color-light"
        link="https://momentum.nationbuilder.com/nec_fund"
        btnText="Support the campaign"
        imgUrl="/img/donate.svg"
        text={<ReactMarkdown source={supportTheCampaign_copy} />}
      />

      {flagWrapper(process.env.REACT_APP_SHOW_STATEMENT_OF_PRINCIPLES, <C2ASection
        bgClass="bg-dark"
        textClass="color-light"
        btnClass="btn-outline-primary"
        link="/principles"
        btnText="Click to read more about our values"
        imgUrl="/img/principles.png"
        text={<ReactMarkdown source={statementOfPrinciples_copy} />}
      />)}

    </Template>
  )
}

export default Landing
